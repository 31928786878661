import React from 'react'
import generatedData from '../../../common/services/data.json'

export type ReportSummaryProps = {
  className?: string
}

const ReportSummary = ({ className = '' }: ReportSummaryProps): JSX.Element => (
  <div
    className={`${
      className ?? ''
    } bg-white shadow overflow-hidden sm:rounded-lg `}
  >
    <div className="px-4 py-5 sm:px-6">
      <h3 className="text-lg leading-6 font-medium">
        Laatste rapportoverzicht
      </h3>
    </div>
    <div className="border-t border-gray-200">
      <dl>
        <div className="bg-gray-50 px-4 py-5 md:grid lg:grid-cols-3 sm:gap-4 sm:px-6">
          <dt className="text-sm font-medium">Waarde participaties</dt>
          <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">{`€ ${new Intl.NumberFormat(
            'nl-NL',
          ).format(generatedData[0].waardeParticpaties)}`}</dd>
        </div>
        <div className="bg-white px-4 py-5 md:grid lg:grid-cols-3 sm:gap-4 sm:px-6">
          <dt className="text-sm font-medium">Maandrendement</dt>
          <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">{`${generatedData[0].maandRendement}%`}</dd>
        </div>
        <div className="bg-gray-50 px-4 py-5 md:grid lg:grid-cols-3 sm:gap-4 sm:px-6">
          <dt className="text-sm font-medium">Rendement YTD</dt>
          <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">{`${generatedData[0].rendementYTD}%`}</dd>
        </div>
      </dl>
    </div>
  </div>
)

export default ReportSummary
